import React,{ useState }  from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import ConfigConstants from '../../config/Config';
import Cookies from 'js-cookie';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Checkmark } from 'react-checkmark'

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button, 
  Modal, 
  ModalBody, 
  ModalHeader
} from "shards-react";

const UserInfo = ({ title }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    name: "",
    surname: "",
    password: "",
    phone: "",
    pushToken: "",
  });

  const [phoneNumber, setPhoneNumber] = useState();
  const [smsVerified, setSmsVerified] = useState(false);
  const [phoneNumberSaved, setPhoneNumberSaved] = useState(false);
  const [verSmsSended, setVerSmsSended] = useState(false);
  const [smsCode, setSmsCode] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [errorModal, setErrorModal] = useState(true);
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleShowModal = () => {
      if(showModal)
        setErrorModal(true);
      setShowModal(!showModal);
  };

  const sendVerificationSmsCode = () => {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.post(ConfigConstants.backendHost + '/user/sendSms', {
        "userId": Cookies.get('userId'),
        "phone": phoneNumber
    }, { headers: headers })
    .then(res => {
        if(res.data.success) {
            setErrorModal(false);
            setModalMessage('Sms verification code sended');
            setShowModal(true);
            setVerSmsSended(true);
        } else {
          setModalMessage(res.data.message);
            setShowModal(true);
            
        }
        
    }) 
    .catch(error => { 
      if (error.response) {
          setModalMessage(error.response.data);
          setShowModal(true);
      } else if (error.request) {
          setModalMessage(error.request.responseJSON);
          setShowModal(true);
      } else {
          setModalMessage(error.message);
          setShowModal(true);
      }
    })
  }

  const handleSmsCodeChange = (event) => {
    const { value } = event.target;
    setSmsCode(value);
  };

  const verifySmsCode = () => {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.post(ConfigConstants.backendHost + '/verification/sms', {
        userId: Cookies.get('userId'),
        code: smsCode
    }, { headers: headers })
    .then(res => {
        if(res.data.success) {
            setErrorModal(false);
            setModalMessage(res.data.message);
            setShowModal(true);
            setSmsVerified(true);
            Cookies.set("phoneNumber", res.data.phoneNumber);  
        } else {
            setModalMessage(res.data.message);
            setShowModal(true);
        }
    })
    .catch(error => { 
      if (error.response) {
          setModalMessage(error.response.data);
          setShowModal(true);
      } else if (error.request) {
          setModalMessage(error.request.responseJSON);
          setShowModal(true);
      } else {
          setModalMessage(error.message);
          setShowModal(true);
      }
    })
  }

  const getUserDetil = () =>  {
    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.get(ConfigConstants.backendHost + '/user/detail?userId=' + Cookies.get('userId'), { headers: headers })
    .then(res => { 
      const response = res.data;

      if(response.success) {
        setFormData({
          username: response.data.username,
          email: response.data.emailAddress,
          name: response.data.name,
          surname: response.data.surname,
          phone: response.data.phoneNumber,
          pushToken: response.data.appToken,
          remaininToken: response.data.tokenCount
        });

        if(response.data.phoneNumber) {
          setPhoneNumber(response.data.phoneNumber);
          setPhoneNumberSaved(true);
          setSmsVerified(response.data.smsVerified);
        }

      } else {
        setModalMessage(response.message);
        setShowModal(true);
      }

    }) 
    .catch(error => { 
      if (error.response) {
          setModalMessage(error.response.data);
          setShowModal(true);
      } else if (error.request) {
          setModalMessage(error.request.responseJSON);
          setShowModal(true);
      } else {
          setModalMessage(error.message);
          setShowModal(true);
      }
    })
  }

  const saveNewUser = () => {
    formData['phone'] = phoneNumber;

    const headers = {
      'Authorization': 'Bearer ' + Cookies.get('jwtToken')
    }

    axios.post(ConfigConstants.backendHost + '/user/save', formData, { headers: headers })
    .then(res => {
      const response = res.data;

      if(response.success) {
        if(response.data.phoneNumber)
          setPhoneNumberSaved(true);

        if(response.data.appToken)
          Cookies.set("appToken", response.data.appToken);  

          setErrorModal(false);
          setModalMessage('User Updated Successfully');
          setShowModal(true);
      } else {
        setModalMessage(response.message);
        setShowModal(true);
      }
      
    }) 
    .catch(error => { 
      if (error.response) {
          setModalMessage(error.response.data);
          setShowModal(true);
      } else if (error.request) {
          setModalMessage(error.request.responseJSON);
          setShowModal(true);
      } else {
          setModalMessage(error.message);
          setShowModal(true);
      }
    }) 
  }

  React.useEffect(() => {
    if(Cookies.get("userId") && Cookies.get("jwtToken"))
      getUserDetil();
    else
      navigate("/login");
 }, [])
  
  return (
    <div>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{title}</h6>
            </CardHeader>
            <ListGroup>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                    <Row form>
                        {/* User Name */}
                        <Col md="3" className="form-group">
                          <label htmlFor="feUsername"><u>Username</u></label> <br></br>
                          &nbsp;<label htmlFor="feUsername">{formData['username']}</label>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="feUsername"><u>Remaining Token</u></label> <br></br>
                          &nbsp;<label htmlFor="feUsername">{formData['remaininToken']}</label>
                        </Col>
                        {/* Email */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feEmail">Email</label>
                          <FormInput
                            type="email"
                            id="feEmail"
                            name="email"
                            onChange={handleInputChange}
                            autoComplete="email"
                            value={formData['email']}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* First Name */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feName">First Name</label>
                          <FormInput
                            id="feName"
                            name="name"
                            onChange={handleInputChange}
                            value={formData['name']}
                          />
                        </Col>
                        {/* Last Name */}
                        <Col md="6" className="form-group">
                          <label htmlFor="feSurname">Last Name</label>
                          <FormInput
                            id="feSurname"
                            name="surname"
                            onChange={handleInputChange}
                            value={formData['surname']}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        {/* Phone Number */}
                        <Col md="6" className="form-group">
                          <label htmlFor="fePhone">Phone Number</label>
                          {/*<FormInput
                            id="fePhone"
                            name="phone"
                            onChange={handleInputChange}
                            value={formData['phone']}
                            />*/}
                          <PhoneInput
                            placeholder="Enter phone number"
                            value={phoneNumber}
                            onChange={setPhoneNumber}/>
                        </Col>
                        {
                          smsVerified &&
                          <Col md="0.1" className="form-group" style={{marginTop: "33px"}}>
                            <Checkmark size='medium'/>
                          </Col>
                        }
                        {
                          phoneNumberSaved && !smsVerified && !verSmsSended &&
                          <Col md="3" className="form-group">
                            <br />
                            <Button theme="accent" onClick={ sendVerificationSmsCode } style={{marginTop: "5px"}}>Send Sms Verify Code</Button>
                          </Col>
                        }
                        {
                          phoneNumberSaved && !smsVerified && verSmsSended && 
                          <Col md="3" className="form-group">
                            <br />
                            <FormInput
                              id="feSmsCode"
                              name="smsCode"
                              onChange={handleSmsCodeChange}
                              value={smsCode}
                              style={{marginTop: "5px"}}
                            />
                          </Col>
                        }
                        {
                          phoneNumberSaved && !smsVerified && verSmsSended &&
                          <Col md="3" className="form-group">
                            <br />
                            <Button theme="accent" onClick={ verifySmsCode } style={{marginTop: "5px"}}>Verify Code</Button>
                          </Col>
                        }
                        {/* App Token 
                        <Col md="6" className="form-group">
                          <label htmlFor="fePushToken">App Token</label>
                          <FormInput
                            id="fePushToken"
                            name="pushToken"
                            onChange={handleInputChange}
                            value={formData['pushToken']}
                          />
                        </Col>*/}
                      </Row>
                      <Button theme="accent" onClick={ saveNewUser }>Save User</Button>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Modal open={showModal} toggle={toggleShowModal}>
            {
              errorModal && 
              <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
            }
            {
              !errorModal && 
              <ModalHeader><i class='fas fa-circle-info' style={{fontSize: '20px', color: 'green'}}></i> Error</ModalHeader>
            }
            <ModalBody>{modalMessage}</ModalBody>
          </Modal>
    </div>
  )
};

UserInfo.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserInfo.defaultProps = {
  title: "User Info"
};

export default UserInfo;
