import React,{ useState }  from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ConfigConstants from "../config/Config";
import Cookies from 'js-cookie';
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Modal, ModalBody, ModalHeader } from "shards-react";

import "../assets/login.css"

const Login = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        if(Cookies.get("userId"))
            navigate("/reminders-dashboard");
     }, [])

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passVisible, setPassVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    const togglePasswordVisible = () => {
        setPassVisible(!passVisible);
    };

    const toggleShowModal = () => {
        setShowModal(!showModal);
    };

    const doLogin = () => {
        axios.post(ConfigConstants.backendHost + '/user/authenticate', {
            "username": username,
            "password": password
        })
        .then(res => {
            const response = res.data;

            if(response.success) {
                Cookies.set("username", response.data.user.username);
                Cookies.set("userId", response.data.user.userId);
                Cookies.set("jwtToken", response.data.token);
    
                if(response.data.user.phoneNumber && response.data.user.smsVerified)
                    Cookies.set("phoneNumber", response.data.user.phoneNumber);
                
    
                if(response.data.user.appToken)
                    Cookies.set("appToken", response.data.user.appToken);
    
                navigate("/reminders-dashboard");
            } else {
                setErrorMessage(response.message);
                setShowModal(true);
            }
        }) 
        .catch(error => { 
            if (error.response) {
                setErrorMessage(error.response.data);
                setShowModal(true);
            } else if (error.request) {
                setErrorMessage(error.request.responseJSON);
                setShowModal(true);
            } else {
                setErrorMessage(error.message);
                setShowModal(true);
            }
        })
    }

    const goRegister = () => {
        navigate("/register");
    }

    return(
        <>
        <div class="login-container">
            <div class="screen">
                <div class="screen__content">
                    <div class="login">
                        <div class="login__field">
                            <i class="login__icon fas fa-user"></i>
                            <input type="text" class="login__input" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                        </div>
                        <div class="login__field">
                            <i class="login__icon fas fa-lock"></i>
                            <input type={!passVisible ? "password" : "text"} class="login__input" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                            <span className="icon" onClick={togglePasswordVisible}>
                                {passVisible ? <EyeOutlined/> : <EyeInvisibleOutlined />}
                            </span>
                        </div>
                        <button class="button login__submit" onClick={doLogin}>
                            <span class="button__text">Log In</span>
                            <i class="button__icon fas fa-chevron-right"></i>
                        </button>				
                    </div>
                    <div class="goToRegister">
                        <div class="goToRegisterText">Don't you have an account?</div>
                        <div class="goToRegisterBtn" onClick={goRegister}>Register</div>
                    </div>
                </div>
                <div class="screen__background">
                    <span class="screen__background__shape screen__background__shape4"></span>
                    <span class="screen__background__shape screen__background__shape3"></span>		
                    <span class="screen__background__shape screen__background__shape2"></span>
                    <span class="screen__background__shape screen__background__shape1"></span>
                </div>		
            </div>
        </div>
         <Modal open={showModal} toggle={toggleShowModal}>
           <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
           <ModalBody>{errorMessage}</ModalBody>
         </Modal>
       </>
    )
}

export default Login;