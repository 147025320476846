
import React,{ useState }  from "react";
import PropTypes from "prop-types";
import axios from 'axios';
import Datetime from 'react-datetime-rocketsourceco';
import ChannelRules from "./ChannelRules";
import ConfigConstants from "../../config/Config";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import "react-datepicker/dist/react-datepicker.css"
import "../../assets/datetime.css"
import "../../assets/toggle.css"

import {
  Card,
  CardHeader, 
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Button,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader
} from "shards-react";


const NewUserInfo = ({ title }) => {
  const navigate = useNavigate();
  
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [channelList, setChannelList] = useState([]);
  const [phoneNumberNotExist, setPhoneNumberNotExist] = useState(false);
  const [appTokenNotExist, setAppTokenNotExist] = useState(false);
  const [totalTokenCost, setTotalTokenCost] = useState(0);
  const [tokenCostMap, setTokenCostMap] = useState({});

  const [rpTooltipOpen, setRpTooltipOpen] = useState(false);
  const [wtTooltipOpen, setWtTooltipOpen] = useState(false);
  const [chTooltipOpen, setChTooltipOpen] = useState(false);
  const [rrcTooltipOpen, setRrcTooltipOpen] = useState(false);

  const [repeatSelected, setRepeatSelected] = useState(false);
  const [repeatRuleCount, setRepeatRuleCount] = useState(1);

  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [channelTooltipMsg, setChannelTooltipMsg] = useState("* Phone Call channel will be added soon \n * Push Notification channel will be added soon ");

  const [channelRule, setChannelRule] = useState({
    channelType: "Email",
    retryCount: "",
    retryPeriod: "",
    waitMinute: ""
  });

  const [reminderRequest, setReminderRequest] = useState({
    userId: Cookies.get('userId'),
    message: "",
    triggerTime: "",
    reminderName: "",
    retryRules: [],
    channelType: "Email"
  });

  React.useEffect(() => {
    if(Cookies.get("userId") && Cookies.get("jwtToken")) {
      setPhoneNumberNotExist(Cookies.get('phoneNumber') === undefined ? true : false);
      setAppTokenNotExist(Cookies.get('appToken') === undefined ? true : false);

      if(Cookies.get('phoneNumber') === undefined)
        setChannelTooltipMsg("* Add phone number for Sms channel \n " + channelTooltipMsg);

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get('jwtToken')
      }

      axios.get(ConfigConstants.backendHost + '/reminder/getTokenCosts', { headers: headers })
        .then(res => {
          const response = res.data;

          if(!response.success) {
            setErrorMessage(response.message);
            setShowModal(true);
          } else {
            setTokenCostMap(response.data.channelTokenCostMap);
          }
        }) 
        .catch(error => { 
          if (error.response) {
              setErrorMessage(error.response.data);
              setShowModal(true);
          } else if (error.request) {
              setErrorMessage(error.request.responseJSON);
              setShowModal(true);
          } else {
              setErrorMessage(error.message);
              setShowModal(true);
          }
      })
    }
    else
      navigate("/login");
 }, [])

 const toggleShowModal = () => {
    setShowModal(!showModal);
  };

  const validDay = function( current ){
    var yesterday = Datetime.moment().subtract( 1, 'day' );
    return current.isAfter( yesterday );
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setReminderRequest((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if(name === 'everyCount')
      setRepeatRuleCount(value);

    if(repeatSelected && name === 'channelType')
      setTotalTokenCost(tokenCostMap[value].ruleCost);
  }; 

  const handleRuleInputChange = (event) => {
    const { name, value } = event.target;

    setChannelRule((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }; 

  /*const handleRetryRuleInputChange = (event) => {
    const { name, value } = event.target;

    if(name === 'everyCount')
      setRepeatRuleCount(value);
  }; */

  const handleToggleCheck = e => {
    if(repeatSelected)
      setTotalTokenCost(0);
    else
      setTotalTokenCost(tokenCostMap["Email"].ruleCost);

    setRepeatSelected(!repeatSelected);
  }

  const saveRule = () => {
    let ruleCost = 0;
    let channelTokens = tokenCostMap[channelRule.channelType];
    
    ruleCost = ruleCost + channelTokens.ruleCost;
    if(parseInt(channelRule.retryCount) > channelTokens.freeRetryCount)
      ruleCost = ruleCost + ((channelRule.retryCount - channelTokens.freeRetryCount) * channelTokens.retryCost);

    setTotalTokenCost(totalTokenCost + ruleCost);

    setChannelList([
      ...channelList,
      channelRule
    ]);
  }

  const createNewReminder = () => {
    if(channelList === null) {
      console.log("Channel rule list is empty !!!");
    } else { 
      try {
        reminderRequest["triggerTime"] = selectedDate.toDate().getTime();
      } catch (e) {
        reminderRequest["triggerTime"] = 0;
      }
      reminderRequest["retryRules"] = channelList;

      const headers = {
        'Authorization': 'Bearer ' + Cookies.get('jwtToken'),
        'Content-Type': 'application/json'
      }

      console.log("Reminder request ", reminderRequest);

      axios.post(ConfigConstants.backendHost + '/reminder/save', reminderRequest, { headers: headers })
      .then(res => { 
        const response = res.data;
        if(response.success)
          navigate("/reminders-dashboard");
        else {
          setErrorMessage(response.message);
          setShowModal(true);
        }
      }) 
      .catch(error => { 
        if (error.response) {
            setErrorMessage(error.response.data);
            setShowModal(true);
        } else if (error.request) {
            setErrorMessage(error.request.responseJSON);
            setShowModal(true);
        } else {
            setErrorMessage(error.message);
            setShowModal(true);
        }
    })
    }

  }
  
  return (
      <div>
            <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">{title}</h6>
            </CardHeader>
            <ListGroup>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                    <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="feReminderName">Reminder Name</label>
                          <FormInput
                            id="feUsefeReminderName"
                            name="reminderName"
                            placeholder="Name for reminder"
                            onChange={handleInputChange}
                          />
                        </Col>
                        <Col md="6" className="form-group"></Col>
                        <Col md="2" className="form-group">
                          {
                            repeatSelected ? 
                            <label htmlFor="feTotalCost"><h6>Token Cost for Each Retry</h6></label> : 
                            <label htmlFor="feTotalCost"><h6>Total Token Cost</h6></label>
                          }
                          <br></br>
                          <label htmlFor="feTotalCost">{totalTokenCost}</label>
                        </Col>
                      </Row>
                    <Row form>
                        <Col md="12" className="form-group">
                          <label htmlFor="feMessage">Message</label>
                          <FormInput
                            id="feUsefeMessagername"
                            name="message"
                            placeholder="Message for reminder"
                            onChange={handleInputChange}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="10">
                        </Col>
                        <Col md="2">
                          <label>Repeatly</label>&nbsp;
                          <label class="switch">
                            <input id="toggleRepeat" type="checkbox" onChange={handleToggleCheck}/>
                            <span class="slider round"></span>
                          </label>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="4" className="form-group">
                          {
                            repeatSelected ? 
                            <label htmlFor="feTrigger">Start Time</label> : 
                            <label htmlFor="feTrigger">Trigger Time</label>
                          }
                          <div><Datetime isValidDate={ validDay } selected={selectedDate} onChange={(date) => setSelectedDate(date)} value={ selectedDate } /></div>
                        </Col>
                        {
                          repeatSelected && 
                          <Col md="2">
                            <label htmlFor="feRepeatRule">Repeat Rule</label>
                            <FormSelect id="feRepeatRule" name="repeatRule" onChange={handleInputChange}>
                              <option value="EMonth">Every {repeatRuleCount > 1 && repeatRuleCount} Month{repeatRuleCount > 1 && 's'}</option>
                              <option value="EWeek">Every {repeatRuleCount > 1 && repeatRuleCount} Week{repeatRuleCount > 1 && 's'}</option>
                              <option value="EDay">Every {repeatRuleCount > 1 && repeatRuleCount} Day{repeatRuleCount > 1 && 's'}</option>
                              <option value="EHour">Every {repeatRuleCount > 1 && repeatRuleCount} Hour{repeatRuleCount > 1 && 's'}</option>
                              <option value="EMinute">Every {repeatRuleCount > 1 && repeatRuleCount} Minute{repeatRuleCount > 1 && 's'}</option>
                            </FormSelect>
                          </Col>
                        }
                        {
                          repeatSelected &&
                          <Col md="2" className="form-group">
                            <label htmlFor="feEveryCount">Repeat Rule Count</label>
                            <span
                              id="RRCTooltip"
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#007bff',
                                color: '#fff',
                                textAlign: 'center',
                                lineHeight: '12px',
                                marginLeft: '5px',
                                fontSize: '10px',
                                cursor: 'pointer',
                                position: 'relative',
                                top: '-5px'
                              }}
                            >?</span>
                            <Tooltip
                              open={rrcTooltipOpen}
                              target="#RRCTooltip"
                              toggle={() => setRrcTooltipOpen(!rrcTooltipOpen)}
                            >
                              You can set every 2 or 3 months, 1 or 5 hours
                            </Tooltip>
                            <FormInput
                              id="feEveryCount"
                              name="everyCount"
                              type="number"
                              placeholder="Count for every rules"
                              onChange={handleInputChange}
                            />
                          </Col>
                        }
                        {
                          repeatSelected &&
                          <Col md="3" className="form-group">
                            <label htmlFor="feReminderChannel">Reminder Channel</label>
                            <span
                              id="CHTooltip"
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#007bff',
                                color: '#fff',
                                textAlign: 'center',
                                lineHeight: '12px',
                                marginLeft: '5px',
                                fontSize: '10px',
                                cursor: 'pointer',
                                position: 'relative',
                                top: '-5px'
                              }}
                            >?</span>
                            <Tooltip
                              open={chTooltipOpen}
                              target="#CHTooltip"
                              toggle={() => setChTooltipOpen(!chTooltipOpen)}
                            >
                              {channelTooltipMsg}
                            </Tooltip>
                            <FormSelect id="feReminderChannel" name="channelType" onChange={handleInputChange}>
                              <option value="Email">Email</option>
                              <option disabled={phoneNumberNotExist} value="Sms">Sms</option> 
                              <option disabled="true" value="Call">Phone Call</option>
                              <option disabled="true" value="Push">Push Notification</option>
                            </FormSelect>
                          </Col>
                        }
                      </Row>
                      {
                        !repeatSelected && 
                        <Row form>
                          <Col md="4" className="form-group">
                            <label htmlFor="feReminderChannel">Reminder Channel</label>
                            <span
                              id="CHTooltip"
                              style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#007bff',
                                color: '#fff',
                                textAlign: 'center',
                                lineHeight: '12px',
                                marginLeft: '5px',
                                fontSize: '10px',
                                cursor: 'pointer',
                                position: 'relative',
                                top: '-5px'
                              }}
                            >?</span>
                            <Tooltip
                              open={chTooltipOpen}
                              target="#CHTooltip"
                              toggle={() => setChTooltipOpen(!chTooltipOpen)}
                            >
                              {channelTooltipMsg}
                            </Tooltip>
                            <FormSelect id="feReminderChannel" name="channelType" onChange={handleRuleInputChange}>
                              <option value="Email">Email</option>
                              <option disabled={phoneNumberNotExist} value="Sms">Sms</option> 
                              <option disabled="true" value="Call">Phone Call</option>
                              <option disabled="true" value="Push">Push Notification</option>
                            </FormSelect>
                          </Col>
                          <Col md="2" className="form-group">
                            <label htmlFor="feRetryCount">Retry Count</label>
                            <FormInput
                              id="feRetryCount"
                              name="retryCount"
                              type="number"
                              placeholder="Retry count"
                              onChange={handleRuleInputChange}
                            />
                          </Col>
                          {
                            channelRule.retryCount > 1 &&
                            <Col md="2" className="form-group">
                              <label htmlFor="feRetryPeriod">Retry Period</label>
                              <span
                                id="RPTooltip"
                                style={{
                                  display: 'inline-block',
                                  width: '12px',
                                  height: '12px',
                                  borderRadius: '50%',
                                  backgroundColor: '#007bff',
                                  color: '#fff',
                                  textAlign: 'center',
                                  lineHeight: '12px',
                                  marginLeft: '5px',
                                  fontSize: '10px',
                                  cursor: 'pointer',
                                  position: 'relative',
                                  top: '-5px'
                                }}
                              >?</span>
                              <Tooltip
                                open={rpTooltipOpen}
                                target="#RPTooltip"
                                toggle={() => setRpTooltipOpen(!rpTooltipOpen)}
                              >
                                Delay between two retries
                              </Tooltip>
                              <FormInput
                                id="feRetryPeriod"
                                name="retryPeriod"
                                type="number"
                                placeholder="Period in minutes"
                                onChange={handleRuleInputChange}
                              />
                            </Col>
                          }
                          {
                            channelList.length > 0 &&
                            <Col md="4" className="form-group">
                              <label htmlFor="feWaitMinute">Wait Time</label>
                              <span
                                id="WTTooltip"
                                style={{
                                  display: 'inline-block',
                                  width: '12px',
                                  height: '12px',
                                  borderRadius: '50%',
                                  backgroundColor: '#007bff',
                                  color: '#fff',
                                  textAlign: 'center',
                                  lineHeight: '12px',
                                  marginLeft: '5px',
                                  fontSize: '10px',
                                  cursor: 'pointer',
                                  position: 'relative',
                                  top: '-5px'
                                }}
                              >?</span>
                              <Tooltip
                                open={wtTooltipOpen}
                                target="#WTTooltip"
                                toggle={() => setWtTooltipOpen(!wtTooltipOpen)}
                              >
                                Delay after previous channel rules ending
                              </Tooltip>
                              <FormInput
                                id="feWaitMinute"
                                name="waitMinute"
                                type="number"
                                placeholder="Delay in minutes after executing preivous rule"
                                onChange={handleRuleInputChange}
                              />
                            </Col>
                          }
                        </Row>
                      }
                      {
                        !repeatSelected && 
                        <Row form>
                          <Col md="10" className="form-group" />
                          <Col md="2" className="form-group">
                            <Button onClick={ saveRule }>Save Rule</Button>
                          </Col> 
                        </Row>
                      }
                      {
                        !repeatSelected && channelList.length > 0 && 
                        <ChannelRules channelList={channelList} />
                      }
                      {
                        repeatSelected &&
                        <Row form>

                        </Row>
                      } 
                      <Button theme="accent" onClick={ createNewReminder }>Create Reminder</Button>         
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
          <Modal open={showModal} toggle={toggleShowModal}>
           <ModalHeader><i class='fas fa-exclamation-circle' style={{fontSize: '20px', color: 'red'}}></i> Error</ModalHeader>
           <ModalBody>{errorMessage}</ModalBody>
         </Modal>
      </div>
  )
};

NewUserInfo.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NewUserInfo.defaultProps = {
  title: "Create New Reminder"
};

export default NewUserInfo;
